.App {
  text-align: center;
}

body {
  /* overflow-x: hidden; */
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"),
    url(./../../assets/fonts/Axiforma-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url(./../../assets/fonts/Gilroy-Light.otf) format("opentype");
}

#mainText {
  font-family: "Gilroy";
  /* font-family: Inter; */

  font-size: 1.3rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container-1 {
  /* animation-duration: 2s; */
  animation-name: slidein;
  /* animation-iteration-count: infinite; */
}

.call-to-action {
  padding-top: "400px";
}

@keyframes slidein {
  from {
    margin-top: 100%;
    /* width: 300%; */
  }

  to {
    margin-top: 0%;
    width: 100%;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header2 {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  /* background-color: #16171a; */
  /* background-color: #d553b7; */
  background-color: #080e2a;

  /* min-height: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(2px + 2vmin);
  /* color: white; */
}

.footer-logo {
  padding-top: 20px;
  margin-bottom: -20px;
}

@media screen and (min-width: 960px) {
  .footer-logo {
    padding-top: 0px;
    margin-bottom: 8px;
    margin-right: -50px;
    width: "200px";
    /* width: 20vh; */
  }
}

.nobr { white-space:nowrap; }

.imagefix {
  margin-bottom: "100px";
}

.section-headers {
  font-size: 2rem;
  font-family: Inter;
  padding-top: 25px;
  font-weight: bold;
  font-weight: 700;
  line-height: 1.4;
}

.section-content {
  /* text-align: left; */
  /* text-align: center; */

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  /* font-family: Inter; */
  /* font-family: Gilroy; */

  font-weight: 400;
  line-height: 1.4;
  font-size: 1rem;
}

.section-content-2 {
  text-align: center;
  padding-left: 40px;
  padding-right: 30px;
  padding-bottom: 30px;
  /* font-family: Inter; */
  /* font-family: Gilroy; */

  font-weight: 400;
  line-height: 1.4;
  font-size: 1rem;
}

.section-images {
  /* min-width: 100px !important; */
  /* min-width: 10px !important; */

  max-width: 75%;
  /* padding: 20px; */
  /* height: 50% */
}
.section-images-long {
  max-width: 60%;
  /* padding-top: 24px; */
}

.section-images-groupMagic {
  /* min-width: 100px !important; */
  /* min-width: 10px !important; */

  max-width: 75%;
  /* height: 50% */
}

@media screen and (min-width: 960px) {
  #official-bio-text {
    margin-top: -30px;
  }

  .section-images {
    /* width: 100%; */
    max-width: 78%;
    height: auto;
  }
  .section-images-long {
    max-width: 80%;
    height: auto;

    /* max-width: 80%; */
  }

  .section-headers {
    font-size: 2rem;
    font-family: Inter;
    padding-top: 20px;
    font-weight: bold;
    font-weight: 700;
    line-height: 1.4;
  }
}

@media screen and (min-width: 480px) {
  .section-content {
    text-align: left;
    padding-left: 40px;
    padding-right: 30px;
    padding-bottom: 30px;
    /* font-family: Inter; */
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.1rem;
  }

  .section-content-2 {
    text-align: center;
    padding-left: 40px;
    padding-right: 30px;
    padding-bottom: 30px;
    /* font-family: Inter; */
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.1rem;
  }
}

.App-header4 {
  background-color: #565e6e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
}

.App-Nav {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.intro-container {
  color: white;

  /* top: 50%; */
  padding: 36vh 0;
  /* padding: 100px 0; */
  /* border: 28px solid green; */
  /* border: 28px solid rgba(255, 255, 255, 0); */
}

.intro-text-one {
  font-size: 2.3rem;
  text-align: center;
  margin-bottom: 40px;
  /* margin-left: 10px; */

  /* padding-left: 100px; */
  /* padding-right: 200px; */
}
.intro-text-two {
  /* font-family: Inter; */

  font-size: 1.2rem;
  text-align: center;
  margin-left: 15px;

  /* padding-left: 100px; */
  /* padding-right: 200px; */
}

.background-parent-container {
  background-image: url(./../../assets/images/topheadshot final.jpg);

  /* background-repeat: no-repeat; */
  /* background-position: center; */
  background-size: cover;
  width: 100%;
  height: 100vh;

  background-position: right 30% bottom 45%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  /* background-size: 70% auto; */
}
.background-parent-container-contact {
  background-image: url(./../../assets/images/ContactUs.jpg);

  /* background-repeat: no-repeat; */
  /* background-position: center; */
  background-size: cover;
  width: 100%;
  /* height: 100vh; */

  background-position: right 40% bottom 95%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  /* background-size: 70% auto; */
  background-size: 130% auto;
  background-color: #08070d;
}
@media screen and (min-width: 480px) {
  .background-parent-container {
    /* background-image: url(./assets/images/topheadshot2.jpg); */

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 80vh;
    min-height: 700px;
  }
}

@media screen and (min-width: 480px) {
  .intro-container {
    /* top: 50%; */
    padding: 26vh 0;
    /* padding: 100px 0; */
    /* border: 28px solid green; */
    /* border: 28px solid rgba(255, 255, 255, 0); */
  }

  .intro-text-one {
    /* font-family: Inter; */

    font-size: 3.8rem;
    text-align: left;
    padding-left: 10vw;
    /* margin-top: -100px; */
    /* padding-right: 200px; */
    /* font-family: Athiti; */
  }
  .intro-text-two {
    font-family: Inter;

    font-size: 1.2rem;
    /* text-align: left; */
    text-align: left;

    padding-left: 10vw;
    margin-top: -20px;
    font-family: Inter;

    /* padding-right: 200px; */
  }

  @media screen and (min-width: 480px) {
    .intro-text-two {
      margin-left: 30px;
      line-height: 24pt;
      /* padding-right: 200px; */
    }
    .intro-text-one-pt2 {
      margin-left: 20px;
    }
    .intro-text-two-pt2 {
      margin-left: 110px;
    }
  }

  .contentContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.shadowed {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.shadowed2 { 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}


/* .shadowedText {
  
} */